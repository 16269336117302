import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipTrIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="tr"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-MLX2VQZ"
    ></IndexPage>
  );
};

export default CitizenshipTrIndexPage;
